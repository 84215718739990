import React from 'react';

import './index.css';

function PublicHomePage() {
  return (
    <div className="public-home-page">
      {/* <div>test: {process.env.REACT_APP_TEST}</div> */}
      <header className="App-header">
        <h1>Veenstra IT Solutions</h1>
      </header>

      <section className="public-home-contact">
        <ul>
          <li><a href="mailto:info@veenstraitsolutions.nl?subject=React development">React Development</a></li>
          <li><a href="mailto:info@veenstraitsolutions.nl?subject=Microsoft Azure/Office Development">Microsoft Azure/Office Development</a></li>
          <li><a href="mailto:info@veenstraitsolutions.nl?subject=Microsoft Azure/Office Consultancy">Microsoft Azure/Office Consultancy</a></li>
          <li><a href="mailto:info@veenstraitsolutions.nl?subject=Project Management">Project Management</a></li>
          <li><a href="mailto:support@veenstraitsolutions.nl?subject=Support">Support</a></li>
        </ul>
      </section>
    </div>
  );
}

export default PublicHomePage;
